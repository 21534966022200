<template>
  <v-form
    ref="form"
    v-model="valid"
    :lazy-validation="lazy"
    onsubmit="return false;"
  >
    <v-container fluid row justify-end>
      <v-progress-linear
        indeterminate
        color="yellow darken-2"
        v-show="x.loading"
      ></v-progress-linear>
      <v-card raised>
        <v-card-title primary-title class="bg-clr">
          <v-btn fab flat icon class="nospace" @click.native="close">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <span v-if="readonly" class="headline"
            >&nbsp;Transporter - {{ username }}</span
          >
          <span v-if="!readonly" class="headline"
            >&nbsp;Edit Transporter - {{ username }}</span
          >
          <v-icon
            v-permissions="'edit-transporter'"
            @click="checkEdit()"
            color="orange darken-1"
            prepend-icon
            style="padding-left: 10px"
            v-if="readonly"
            >create</v-icon
          >
        </v-card-title>
        <v-card-text class="content-border">
          <div class="heading primary-clr pt-3">Company Details</div>
          <v-layout row>
            <v-flex xs12 sm6 md3 v-if="readonly">
              <div v-if="profilePicURL" class="subheading muted caption">
                Profile Image
              </div>
              <a
                v-if="profilePicURL"
                :href="profilePicURL"
                title="Profile Image"
                single-line
                target="_blank"
                class
              >
                <img
                  :src="profilePicURL"
                  height="70px"
                  width="70px"
                  class="thumb-img"
                  alt
                />
              </a>
            </v-flex>
            <v-flex xs12 sm6 md3 v-if="!readonly">
              <div v-if="profilePicURL" class="upload-btn-wrapper">
                <a
                  v-if="profilePicURL"
                  :href="profilePicURL"
                  title="Profile Picture"
                  target="_blank"
                  class
                >
                  <img
                    :src="profilePicURL"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12 md4>
              <v-radio-group
                class="mt-0 pt-0"
                v-model="type"
                row
                single-line
                :disabled="readonly"
                :mandatory="true"
                :rules="[rules.required]"
              >
                <div>
                  <div class="muted caption">Registered As</div>
                  <div class>
                    <v-layout>
                      <v-radio
                        label="Transporter"
                        value="Transporter"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Broker"
                        value="Broker"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </div>
              </v-radio-group>
            </v-flex>
            <v-flex xs12 md1></v-flex>

            <v-flex xs12 v-if="!readonly" sm6 md3>
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Profile Picture</button>
                <input type="file" name="myfile" @change="profilePicChange" />
              </div>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12 md4>
              <div class="subheading muted caption">Name of Company*</div>
              <div class="heading">
                <v-text-field
                  label="Name of Company *"
                  v-model="companyName"
                  required
                  :disabled="readonly"
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                />
              </div>
            </v-flex>
            <v-flex xs12 sm6 md1></v-flex>
            <v-flex xs12 v-if="readonly" sm6 md3 pb-2>
              <div v-if="tradeLicense" class="subheading muted caption">
                {{
                  countrySortName != "PAK"
                    ? "Trade License/CR Copy"
                    : "NTN Certificate"
                }}
              </div>
              <a
                v-if="
                  tradeLicense &&
                  tradeLicense.substr(tradeLicense.lastIndexOf('.') + 1) ===
                    'pdf'
                "
                :href="tradeLicense"
                class="document-link"
                style="text-decoration: none; outline: none"
                target="_blank"
              >
                <v-icon
                  color="red darken-1 "
                  flat
                  prepend-icon
                  size="50"
                  title="Document"
                  >picture_as_pdf</v-icon
                >
              </a>
              <a
                v-else-if="tradeLicense"
                :href="tradeLicense"
                title="Trade License"
                single-line
                target="_blank"
                class
              >
                <img
                  :src="tradeLicense"
                  height="70px"
                  width="70px"
                  class="thumb-img"
                  alt
                />
              </a>
            </v-flex>

            <v-flex xs12 v-if="!readonly" sm6 md5>
              <div class="upload-btn-wrapper">
                <button class="btn">
                  {{
                    countrySortName != "PAK"
                      ? "Upload Trade License/CR Copy"
                      : "Upload NTN Certificate"
                  }}
                </button>
                <input
                  ref="fileUpload"
                  type="file"
                  name="myfile"
                  accept=".pdf, .jpeg, .jpg, .png"
                  @change="tradeLicenseChange"
                />
              </div>
              <div v-if="tradeLicense" class="upload-btn-wrapper">
                <a
                  v-if="
                    tradeLicense &&
                    tradeLicense.substr(tradeLicense.lastIndexOf('.') + 1) ===
                      'pdf'
                  "
                  :href="tradeLicense"
                  class="document-link"
                  style="text-decoration: none; outline: none"
                  target="_blank"
                >
                  <v-icon
                    color="red darken-1 "
                    flat
                    prepend-icon
                    size="50"
                    title="Document"
                    >picture_as_pdf</v-icon
                  >
                </a>
                <a
                  v-else-if="tradeLicense"
                  :href="tradeLicense"
                  title="Trade License"
                  target="_blank"
                  class
                >
                  <img
                    :src="tradeLicense"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>

            <v-spacer />
          </v-layout>
          <v-layout row>
            <v-flex xs12 md4>
              <div class="subheading muted caption">Username*</div>
              <div class="heading">
                <v-text-field
                  label="Username *"
                  v-model="username"
                  :rules="[rules.noWhiteSpace, rules.required]"
                  single-line
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  required
                  maxlength="90"
                />
              </div>
            </v-flex>

            <v-flex xs12 sm6 md1></v-flex>
            <v-flex xs12 v-if="readonly" sm6 md5 pb-2>
              <div v-if="vatCertificate" class="subheading muted caption">
                {{
                  countrySortName != "PAK"
                    ? "VAT Certificate"
                    : "STRN Certificate"
                }}
              </div>
              <a
                v-if="
                  vatCertificate &&
                  vatCertificate.substr(vatCertificate.lastIndexOf('.') + 1) ===
                    'pdf'
                "
                :href="vatCertificate"
                class="document-link"
                style="text-decoration: none; outline: none"
                target="_blank"
              >
                <v-icon
                  color="red darken-1 "
                  flat
                  prepend-icon
                  size="50"
                  title="Document"
                  >picture_as_pdf</v-icon
                >
              </a>

              <a
                v-else-if="vatCertificate"
                :href="vatCertificate"
                title="VAT Certificate"
                single-line
                target="_blank"
                class
              >
                <img
                  :src="vatCertificate"
                  height="70px"
                  width="70px"
                  class="thumb-img"
                  alt
                />
              </a>
            </v-flex>

            <v-flex xs12 v-if="!readonly" sm6 md5>
              <div class="upload-btn-wrapper">
                <button class="btn">
                  {{
                    countrySortName != "PAK"
                      ? "Upload VAT Certificate"
                      : "Upload STRN Certificate"
                  }}
                </button>
                <input
                  type="file"
                  ref="fileUpload"
                  accept=".pdf, .jpeg, .jpg, .png"
                  name="myfile"
                  @change="vatCertificateChange"
                />
              </div>
              <div v-if="vatCertificate" class="upload-btn-wrapper">
                <a
                  v-if="
                    vatCertificate &&
                    vatCertificate.substr(
                      vatCertificate.lastIndexOf('.') + 1
                    ) === 'pdf'
                  "
                  :href="vatCertificate"
                  class="document-link"
                  style="text-decoration: none; outline: none"
                  target="_blank"
                >
                  <v-icon
                    color="red darken-1 "
                    flat
                    prepend-icon
                    size="50"
                    title="Document"
                    >picture_as_pdf</v-icon
                  >
                </a>
                <a
                  v-else-if="vatCertificate"
                  :href="vatCertificate"
                  title=" VAT Certificate"
                  target="_blank"
                  class
                >
                  <img
                    :src="vatCertificate"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12 md4>
              <div class="subheading muted caption">Email Id*</div>
              <div class="heading">
                <v-text-field
                  label="Email Id *"
                  :rules="[rules.required]"
                  v-model="emailId"
                  single-line
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  required
                />
              </div>
            </v-flex>

            <v-flex xs12 sm6 md1></v-flex>
            <v-flex
              xs12
              v-if="readonly && this.countrySortName != 'PAK'"
              sm6
              md4
              style="padding-left: 38px"
            >
              <div v-if="passportFront" class="subheading muted caption">
                Passport Front Image
              </div>
              <a
                v-if="
                  passportFront &&
                  passportFront.substr(passportFront.lastIndexOf('.') + 1) ===
                    'pdf'
                "
                :href="passportFront"
                style="text-decoration: none; outline: none"
                class="document-link"
                target="_blank"
              >
                <v-icon
                  color="red darken-1 "
                  flat
                  prepend-icon
                  size="50"
                  title="Document"
                  >picture_as_pdf</v-icon
                >
              </a>
              <a
                v-else-if="passportFront"
                :href="passportFront"
                title=" Passport Front Image"
                single-line
                target="_blank"
                class
              >
                <img
                  :src="passportFront"
                  height="70px"
                  width="70px"
                  class="thumb-img"
                  alt
                />
              </a>
            </v-flex>

            <v-flex
              xs12
              v-if="!readonly && this.countrySortName != 'PAK'"
              sm6
              md4
              style="padding-left: 38px"
            >
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Passport Front Image</button>
                <input
                  ref="fileUpload"
                  accept=".pdf, .jpeg, .jpg, .png"
                  type="file"
                  name="myfile"
                  @change="passportFrontChange"
                />
              </div>
              <div v-if="passportFront" class="upload-btn-wrapper">
                <a
                  v-if="
                    passportFront &&
                    passportFront.substr(passportFront.lastIndexOf('.') + 1) ===
                      'pdf'
                  "
                  :href="passportFront"
                  style="text-decoration: none; outline: none"
                  class="document-link"
                  target="_blank"
                >
                  <v-icon
                    color="red darken-1 "
                    flat
                    prepend-icon
                    size="50"
                    title="Document"
                    >picture_as_pdf</v-icon
                  >
                </a>
                <a
                  v-else-if="passportFront"
                  :href="passportFront"
                  title=" Passport Front Image"
                  target="_blank"
                  class
                >
                  <img
                    :src="passportFront"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>

            <v-flex
              xs12
              v-if="readonly && this.countrySortName != 'PAK'"
              sm6
              md4
            >
              <div v-if="passportBack" class="subheading muted caption">
                Passport Back Image
              </div>
              <a
                v-if="
                  passportBack &&
                  passportBack.substr(passportBack.lastIndexOf('.') + 1) ===
                    'pdf'
                "
                :href="passportBack"
                style="text-decoration: none; outline: none"
                class="document-link"
                target="_blank"
              >
                <v-icon
                  color="red darken-1 "
                  flat
                  prepend-icon
                  size="50"
                  title="Document"
                  >picture_as_pdf</v-icon
                >
              </a>
              <a
                v-else-if="passportBack"
                :href="passportBack"
                title=" Passport Back Image"
                single-line
                target="_blank"
                class
              >
                <img
                  :src="passportBack"
                  height="70px"
                  width="70px"
                  class="thumb-img"
                  alt
                />
              </a>
            </v-flex>

            <v-flex
              xs12
              v-if="!readonly && this.countrySortName != 'PAK'"
              sm6
              md4
            >
              <div class="upload-btn-wrapper">
                <button class="btn">Upload Passport Back Image</button>

                <input
                  ref="fileUpload"
                  accept=".pdf, .jpeg, .jpg, .png"
                  type="file"
                  name="myfile"
                  @change="passportBackChange"
                />
              </div>
              <div v-if="passportBack" class="upload-btn-wrapper">
                <a
                  v-if="
                    passportBack &&
                    passportBack.substr(passportBack.lastIndexOf('.') + 1) ===
                      'pdf'
                  "
                  :href="passportBack"
                  style="text-decoration: none; outline: none"
                  class="document-link"
                  target="_blank"
                >
                  <v-icon
                    color="red darken-1 "
                    flat
                    prepend-icon
                    size="50"
                    title="Document"
                    >picture_as_pdf</v-icon
                  >
                </a>
                <a
                  v-else-if="passportBack"
                  :href="passportBack"
                  title="Passport Back Image"
                  target="_blank"
                  class
                >
                  <img
                    :src="passportBack"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
            <v-flex
              xs12
              v-if="readonly && this.countrySortName == 'PAK'"
              sm6
              style="padding-left: 38px"
              md4
            >
              <div v-if="CNICFront" class="subheading muted caption">
                CNIC Front Image
              </div>
              <a
                v-if="
                  CNICFront &&
                  CNICFront.substr(CNICFront.lastIndexOf('.') + 1) === 'pdf'
                "
                :href="CNICFront"
                style="text-decoration: none; outline: none"
                class="document-link"
                target="_blank"
              >
                <v-icon
                  color="red darken-1 "
                  flat
                  prepend-icon
                  size="50"
                  title="Document"
                  >picture_as_pdf</v-icon
                >
              </a>
              <a
                v-else-if="CNICFront"
                :href="CNICFront"
                title=" CNIC Front Image"
                single-line
                target="_blank"
                class
              >
                <img
                  :src="CNICFront"
                  height="70px"
                  width="70px"
                  class="thumb-img"
                  alt
                />
              </a>
            </v-flex>
            <v-flex
              xs12
              v-if="!readonly && this.countrySortName == 'PAK'"
              sm6
              md4
            >
              <div class="upload-btn-wrapper">
                <button class="btn">Upload CNIC Front Image</button>

                <input
                  ref="fileUpload"
                  accept=".pdf, .jpeg, .jpg, .png"
                  type="file"
                  name="myfile"
                  @change="CnicFrontChange"
                />
              </div>
              <div v-if="CNICFront" class="upload-btn-wrapper">
                <a
                  v-if="
                    CNICFront &&
                    CNICFront.substr(CNICFront.lastIndexOf('.') + 1) === 'pdf'
                  "
                  :href="CNICFront"
                  style="text-decoration: none; outline: none"
                  class="document-link"
                  target="_blank"
                >
                  <v-icon
                    color="red darken-1 "
                    flat
                    prepend-icon
                    size="50"
                    title="Document"
                    >picture_as_pdf</v-icon
                  >
                </a>
                <a
                  v-else-if="CNICFront"
                  :href="CNICFront"
                  title="CNIC Front Image"
                  target="_blank"
                  class
                >
                  <img
                    :src="CNICFront"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>
            <v-flex
              xs12
              v-if="readonly && this.countrySortName == 'PAK'"
              sm6
              md4
            >
              <div v-if="CNICBack" class="subheading muted caption">
                CNIC Back Image
              </div>
              <a
                v-if="
                  CNICBack &&
                  CNICBack.substr(CNICBack.lastIndexOf('.') + 1) === 'pdf'
                "
                :href="CNICBack"
                style="text-decoration: none; outline: none"
                class="document-link"
                target="_blank"
              >
                <v-icon
                  color="red darken-1 "
                  flat
                  prepend-icon
                  size="50"
                  title="Document"
                  >picture_as_pdf</v-icon
                >
              </a>
              <a
                v-else-if="CNICBack"
                :href="CNICBack"
                title=" CNIC Back Image"
                single-line
                target="_blank"
                class
              >
                <img
                  :src="CNICBack"
                  height="70px"
                  width="70px"
                  class="thumb-img"
                  alt
                />
              </a>
            </v-flex>
            <v-flex
              xs12
              v-if="!readonly && this.countrySortName == 'PAK'"
              sm6
              md4
            >
              <div class="upload-btn-wrapper">
                <button class="btn">Upload CNIC Back Image</button>

                <input
                  ref="fileUpload"
                  accept=".pdf, .jpeg, .jpg, .png"
                  type="file"
                  name="myfile"
                  @change="CnicBackChange"
                />
              </div>
              <div v-if="CNICBack" class="upload-btn-wrapper">
                <a
                  v-if="
                    CNICBack &&
                    CNICBack.substr(CNICFront.lastIndexOf('.') + 1) === 'pdf'
                  "
                  :href="CNICBack"
                  style="text-decoration: none; outline: none"
                  class="document-link"
                  target="_blank"
                >
                  <v-icon
                    color="red darken-1 "
                    flat
                    prepend-icon
                    size="50"
                    title="Document"
                    >picture_as_pdf</v-icon
                  >
                </a>
                <a
                  v-else-if="CNICBack"
                  :href="CNICBack"
                  title="CNIC Back Image"
                  target="_blank"
                  class
                >
                  <img
                    :src="CNICBack"
                    height="70px"
                    width="70px"
                    class="thumb-img"
                    alt
                  />
                </a>
              </div>
            </v-flex>

            <v-spacer />
          </v-layout>
          <v-layout row>
            <v-flex xs12 md4>
              <div class="subheading muted caption">Password</div>
              <div class="heading">
                <v-text-field
                  label="Password "
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  single-line
                  v-model="password"
                  type="password"
                />
              </div>
            </v-flex>

            <v-spacer />
          </v-layout>
          <v-layout row v-if="this.countrySortName != 'PAK'">
            <v-flex xs12 md4>
              <div class="subheading muted caption">Trade Licence Number *</div>
              <div class="heading">
                <v-text-field
                  label="Trade Licence Number *"
                  v-model="tradeLicenseNumber"
                  required
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  single-line
                />
              </div>
            </v-flex>

            <v-spacer />
          </v-layout>
          <v-layout row v-if="this.countrySortName != 'PAK'">
            <v-flex xs12 md4>
              <div
                v-if="type == 'Transporter'"
                class="subheading muted caption"
              >
                VAT Number
              </div>
              <div v-else class="subheading muted caption">VAT Number</div>
              <div class="heading">
                <v-text-field
                  :label="type == 'Transporter' ? 'VAT Number' : 'VAT Number'"
                  v-model="vatNumber"
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  single-line
                />
              </div>
            </v-flex>

            <v-spacer />
          </v-layout>
          <v-layout row v-if="this.countrySortName != 'PAK'">
            <v-flex xs12 md4>
              <div class="subheading muted caption">Passport number*</div>
              <div class="heading">
                <v-text-field
                  autocomplete="off"
                  label="Passport Number *"
                  v-model="passport"
                  :disabled="readonly"
                  required
                  :rules="[rules.required]"
                  class="pt-0 currencyTitle"
                  single-line
                />
              </div>
            </v-flex>

            <v-spacer />
          </v-layout>

          <v-layout row>
            <v-spacer />
          </v-layout>
          <v-layout>
            <v-flex xs5 md4>
              <div class="subheading muted caption">Secondary Email Id</div>
              <div class="heading">
                <v-combobox
                  v-model="model"
                  :disabled="readonly"
                  append-icon
                  hint="Maximum 3 emails"
                  label="Secondary Email Id"
                  multiple
                  chips
                  single-line
                  deletable-chips
                  class="tag-input pt-0 currencyTitle"
                  v-on:keyup.17="checkValue"
                  type="email"
                  small-chips
                ></v-combobox>
              </div>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12 class="pr-1" sm6 md2>
              <div class="subheading muted caption">Country Code*</div>
              <div class="heading">
                <v-autocomplete
                  v-model="countryCode"
                  id="countryCode"
                  item-text="dialing_code"
                  item-value="dialing_code"
                  label="Country Code *"
                  :items="countryCodes"
                  persistent-hint
                  return-object
                  class="pt-0 currencyTitle"
                  single-line
                  :disabled="readonly"
                ></v-autocomplete>
              </div>
            </v-flex>

            <v-flex xs12 class="pr-1" sm6 md2>
              <div class="subheading muted caption">Phone Number*</div>
              <div class="heading">
                <v-text-field
                  single-line
                  label="Phone Number*"
                  class="pt-0 currencyTitle"
                  :disabled="readonly"
                  :rules="[rules.phoneNumber, rules.required]"
                  v-model="phoneNo"
                ></v-text-field>
              </div>
            </v-flex>

            <v-spacer />
          </v-layout>
          <v-layout>
            <v-flex xs12 md4>
              <div class="subheading muted caption">Country*</div>
              <div class="heading">
                <v-text-field
                  readonly
                  label="Select Currency"
                  class="pt-0 currencyTitle"
                  v-model="countrySortName"
                  :value="countrySortName"
                  single-line
                />
              </div>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 md4>
              <div class="subheading muted caption">
                Choose Transporter Type*
              </div>
              <div class="heading">
                <v-select
                  :items="UserTypes"
                  v-model="UserType"
                  single-line
                  class="pt-0 currencyTitle"
                  disabled
                  :rules="[rules.required]"
                  label="Choose Transporter Type*"
                ></v-select>
              </div>
            </v-flex>
          </v-layout>
          <v-layout v-if="this.UserType == 1 && this.countrySortName == 'UAE'">
            <v-flex xs12 md4>
              <div class="subheading muted caption">All Banks Name</div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  attach
                  :items="bankInfo"
                  item-text="bankName"
                  item-value="bankName"
                  v-model="bankName"
                  :value="bankName"
                  label="All Banks Name"
                  class="pt-0 currencyTitle"
                  :disabled="readonly"
                  single-line
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex xs12 md4 pl-3>
              <div class="subheading muted caption">Account Holder Name</div>
              <v-text-field
                class="pt-0 currencyTitle"
                v-model="bankHolderName"
                :xvalue="bankHolderName"
                label="Account Holder Name"
                :rules="[rules.onlyEnglish]"
                :disabled="readonly"
                single-line
              ></v-text-field
            ></v-flex>

            <!-- <v-flex xs12 md4>
              <div class="subheading muted caption">All Transporter</div>
              <div class="heading">
                <v-autocomplete
                  style="background-color: white !important"
                  attach
                  :items="transporters"
                  v-model="transporterId"
                  label="All Transporter*"
                  multiple
                  @change="!mainError ? (mainError = null) : ''"
                  single-line
                  class="pt-0 currencyTitle"
                  required
                ></v-autocomplete>
              </div>
            </v-flex> -->
          </v-layout>

          <v-layout v-if="this.countrySortName == 'PAK'">
            <v-flex xs12 md4>
              <div class="subheading muted caption">Contact Person Name*</div>
              <div class="heading">
                <v-text-field
                  label="Contact Person Name  *"
                  v-model="ContactPersonName"
                  single-line
                  :disabled="readonly"
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                  required
                />
              </div>
            </v-flex>
          </v-layout>
          <v-layout v-if="this.countrySortName == 'PAK'">
            <v-flex xs12 md4>
              <div class="subheading muted caption">NTN Number*</div>
              <div class="heading">
                <v-text-field
                  label="NTN Number *"
                  v-model="NTNNumber"
                  single-line
                  class="pt-0 currencyTitle"
                  :rules="[rules.noWhiteSpace, rules.required]"
                  required
                  :disabled="readonly"
                />
              </div>
            </v-flex>
          </v-layout>
          <v-layout v-if="this.countrySortName == 'PAK'">
            <v-flex xs12 md4>
              <div class="subheading muted caption">STRN Number</div>
              <div class="heading">
                <v-text-field
                  label="STRN Number"
                  :disabled="readonly"
                  v-model="STRNNumber"
                  single-line
                  class="pt-0 currencyTitle"
                  required
                  counter
                  maxlength="13"
                />
              </div>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-spacer />
          </v-layout>
          <v-flex xs12 md4>
            <v-text-field
              label="Comments *"
              single-line
              v-model="comment"
              :disabled="readonly"
              :rules="[rules.required]"
            />
          </v-flex>
          <v-spacer />

          <div
            class="my-3"
            v-if="haserror"
            style="width: 50%; margin: auto; text-align: center"
          >
            <v-alert v-model="haserror" class="py-2" outline color="red">{{
              x.error
            }}</v-alert>
          </div>
          <hr class="divider-rule" />
          <v-layout>
            <v-spacer />
            <v-btn
              size="large"
              color="orange darken-1"
              style="color: white"
              v-if="!readonly"
              @click.native="checkUpdate()"
              >Update Profile</v-btn
            >
            <v-btn
              size="large"
              color="grey darken-3"
              v-if="!readonly"
              flat
              style="color: white"
              @click.native="
                readonly = !readonly;
                getTransporter();
              "
              >Cancel</v-btn
            >
          </v-layout>
        </v-card-text>
      </v-card>
      <success-dialog
        content="Edited successfully"
        :show="x.registerSuccess"
        :onclose="closeRegistration"
      />
    </v-container>
  </v-form>
</template>

<script>
import SuccessDialog from "@/components/Common/SuccessDialog";
import { EventBus } from "../../event-bus.js";
import ErrorBox from "@/components/Common/ErrorBox";
import moment from "moment";
import { StorageKeys, UserTypes, countries } from "../../constants/constants";
import { getUserById, transporter } from "../../constants/api-urls";
export default {
  created() {
    this.fetchBank();
    if (localStorage.getItem("workingCountries")) {
      let workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.countrySortName = key.value;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "transporter-detail") {
        this.$router.push(`/transporter`);
      }
      this.countrySortName = val.value;
    });
    this.UserTypes = UserTypes;
    this.countryCodes = countries;
    this.getTransporter();
    this.getUserById();
  },
  components: {
    SuccessDialog,
    ErrorBox,
  },
  props: {
    profile: Object,
  },
  data() {
    return {
      transporterType: [
        { value: "Transporter", text: "Transporter" },
        { value: "Broker", text: "Broker" },
      ],
      type: "",
      STRNNumber: "",
      tradeLicenseNumber: "",
      vatNumber: "",
      passport: "",
      CNICFront: "",
      CNICBack: "",
      operationName: "",
      ContactPersonName: "",
      NTNNumber: "",
      countrySortName: "",
      multipleCountry: [],
      phoneNo: "",
      countryCodes: [],
      countryCode: "",
      valid: true,
      lazy: false,
      readonly: true,
      model: [],
      companyName: "",
      username: "",
      profilePicURL: "",
      secondaryEmail: [],
      UserTypes: [],
      UserType: "",
      emailId: "",
      comment: "",
      passportBack: "",
      passportFront: "",
      tradeLicense: "",
      vatCertificate: "",
      password: "",
      bankHolderName: "",
      bankName: "",
      bankInfo: [],

      processing: false,
      x: {
        loading: false,
        error: null,
        message: "",
        registerSuccess: false,
      },
      rules: {
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "This field is required.",
        required: (value) => !!value || "This field is required.",
        min: (v) =>
          v.length >= 4 || "Password length must be of minimum 4 characters",
        phoneNumber: (v) =>
          /^[0-9]{5,15}$/.test(v) || "Please Enter Valid Phone No.",
        NTNNumber: (v) =>
          /^([a-zA-Z0-9_-]{13})$/.test(v) || "Please Enter Valid Number",
        emailRules: (v) =>
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
            v
          ) || "E-mail must be valid",
        userName: (v) =>
          /^[a-z0-9_-]{6,15}$/.test(v) ||
          "Username must be of minimum 6 characters.",
      },
    };
  },
  computed: {
    haserror() {
      return this.x.error !== null;
    },
  },
  watch: {
    model(val) {
      if (val.length > 3) {
        this.$nextTick(() => this.model.pop());
      }
    },
  },
  methods: {
    async checkEdit() {
      this.x.error = null;
      this.haserror = false;
      this.operationName = "edit-transporter";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.readonly = !this.readonly;
      } else {
        return;
      }
    },
    fetchBank() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {};

      let { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}/dmsAdmin/bankListForDriver`, body, config)
        .then(
          (response) => {
            let data = response.data.data;
            console.log(data);
            this.bankInfo = data;
            // data.forEach((element) => {
            //   this.bankInfo.push({
            //     text: element.bankName,
            //     value: element._id,
            //   });
            // });
            // console.log(this.banking.info);

            // this.bankName = response.data.data[2].bankName;
          },
          (error) => {
            this.error = "Something went wrong. Please try again later!";
            this.loading = false;
          }
        );
    },
    async checkUpdate() {
      this.x.error = null;
      this.haserror = false;
      this.operationName = "edit-transporter";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.editTransporter();
      } else {
        this.readonly = !this.readonly;
        return;
      }
    },
    getUserById() {
      this.userId = localStorage.getItem("userId");
      this.loading = true;
      let url = getUserById;
      delete this.axios.defaults.headers.common["token"];
      let body = {
        userId: localStorage.getItem("userId"),
      };
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          response.data.data.countrySortName.forEach((e) => {
            this.multipleCountry.push(e);
          });
        },
        (error) => {
          this.x.error = "Failed to fetch country";
        }
      );
    },
    tradeLicenseChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 1);
    },
    vatCertificateChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 2);
    },
    passportFrontChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 3);
    },
    passportBackChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 4);
    },
    profilePicChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createProfileImage(files[0], 5);
    },
    CnicFrontChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 6);
    },
    CnicBackChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], 7);
    },
    createProfileImage(file, status) {
      this.x.error = null;
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.$refs.fileUpload.value = "";
        this.x.error = "Please upload proof with size less than 5MB!";

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg"
      ) {
        this.$refs.fileUpload.value = "";

        this.x.error = "Please upload proof of file type png , jpg or jpeg !";

        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file, status);
      }
    },
    createImage(file, status) {
      this.x.error = null;
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.$refs.fileUpload.value = "";
        this.x.error = "Please upload proof with size less than 5MB!";
        setTimeout(() => {
          this.x.error = null;
        }, 4000);

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.$refs.fileUpload.value = "";

        this.x.error =
          "Please upload proof of file type png , jpg ,jpeg or pdf!";

        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file, status);
      }
    },
    upload(file, statusChange) {
      this.loading = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}/dmsAdmin/uploadImage`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          const { statusCode } = response.data;
          if (statusCode === 200) {
            this.loading = false;
            if (statusChange == 1) {
              this.tradeLicense = response.data.data.original;
            } else if (statusChange == 2) {
              this.vatCertificate = response.data.data.original;
            } else if (statusChange == 3) {
              this.passportFront = response.data.data.original;
            } else if (statusChange == 4) {
              this.passportBack = response.data.data.original;
            } else if (statusChange == 5) {
              this.profilePicURL = response.data.data.original;
            } else if (statusChange == 6) {
              this.CNICFront = response.data.data.original;
            } else if (statusChange == 7) {
              this.CNICBack = response.data.data.original;
            }
          } else {
            this.error = "Failed to upload image";
          }
          this.loading = false;
        },
        (error) => {
          this.error = "Failed to upload image";
          this.loading = false;
        }
      );
    },
    getJobTypeValue(value) {
      switch (value) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    close() {
      this.$router.push(`/transporter`);
    },
    checkValue() {
      this.communicateDialog.search = "";
    },
    closeRegistration() {
      this.registerSuccess = false;
      this.$router.go(-1);
    },
    updateTags() {
      this.$nextTick(() => {
        this.communicateDialog.select.push(
          ...this.communicateDialog.search.split(",")
        );
        this.$nextTick(() => {
          this.communicateDialog.search = "";
        });
      });
    },
    getTransporter() {
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";

        return;
      }

      this.loading = true;
      let url = transporter.transporterDetails;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        transporterId: this.$route.params.id,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;
          this.type = response.data.data.transporterType;
          this.ContactPersonName = response.data.data.ContactPersonName;
          this.STRNNumber = response.data.data.STRNNumber;
          this.NTNNumber = response.data.data.NTNNumber;
          this.transporterId = response.data.data._id;
          this.countryCode = response.data.data.countryCode;
          this.profilePicURL = response.data.data.profilePicURL;
          this.phoneNo = response.data.data.phoneNo;
          this.companyName = response.data.data.companyName;
          this.username = response.data.data.transporterName;
          this.emailId = response.data.data.email;
          this.countrySortName = response.data.data.workingCountry;
          this.tradeLicenseNumber = response.data.data.tradeLicenseNumber;
          this.bankHolderName = response.data.data.bankHolderName;
          this.bankName = response.data.data.bankName;
          if (response.data.data.vatNumber) {
            this.vatNumber = response.data.data.vatNumber;
          }

          this.passport = response.data.data.passportNumber;

          this.tradeLicense = response.data.data.tradeLicense;
          this.vatCertificate = response.data.data.vatCertificate;
          this.passportFront = response.data.data.passportFront;
          this.passportBack = response.data.data.passportBack;
          this.CNICFront = response.data.data.cnicBackImage;
          this.CNICBack = response.data.data.cnicBackImage;
          this.UserType = response.data.data.jobType;
          this.$eventBus.$emit("transporter-type", this.UserType);
          if (!response.data.data.jobType) {
            this.UserType = "1";
          }
          this.model = response.data.data.secondaryEmail;
        },
        (error) => {
          this.x.error = "Failed to Fetch User Details";
        }
      );
    },

    editTransporter() {
      if (this.$refs.form.validate()) {
        if (this.processing) {
          return;
        } else {
          this.x.error = null;
          if (this.password && !this.password.match(/^[a-zA-Z0-9]{4,}$/)) {
            this.x.error =
              "Please provide password length of minimum 4 characters";
            this.x.loading = false;
            return false;
          }
          if (
            !this.tradeLicense &&
            this.UserType == 1 &&
            this.countrySortName == "PAK"
          ) {
            this.x.error =
              "Please provide NTN certificate image before moving ahead";
            this.x.loading = false;
            return false;
          }
          if (
            !this.tradeLicenseNumber &&
            this.UserType == 1 &&
            this.countrySortName != "PAK"
          ) {
            this.x.error =
              "Please provide trade license number before moving ahead";
            this.x.loading = false;
            return false;
          }
          if (
            !this.tradeLicense &&
            this.UserType == 1 &&
            this.countrySortName != "PAK"
          ) {
            this.x.error =
              "Please provide trade license image before moving ahead";
            this.x.loading = false;
            return false;
          }
          // if (
          //   !this.vatNumber &&
          //   this.UserType == 1 &&
          //   this.countrySortName != "PAK" &&
          //   this.type == "Transporter"
          // ) {
          //   this.x.error = "Please provide VAT number before moving ahead";
          //   this.x.loading = false;
          //   return false;
          // }
          if (
            !this.vatCertificate &&
            this.UserType == 1 &&
            this.countrySortName != "PAK" &&
            this.type == "Transporter"
          ) {
            this.x.error =
              "Please provide VAT certificate image before moving ahead";
            this.x.loading = false;
            return false;
          }

          if (
            !this.passport &&
            this.UserType == 1 &&
            this.countrySortName != "PAK"
          ) {
            this.x.error = "Please provide passport number before moving ahead";
            this.x.loading = false;
            return false;
          }
          if (
            !this.passportFront &&
            this.UserType == 1 &&
            this.countrySortName != "PAK"
          ) {
            this.x.error =
              "Please provide passport front image before moving ahead";
            this.x.loading = false;
            return false;
          }
          if (
            !this.passportBack &&
            this.UserType == 1 &&
            this.countrySortName != "PAK"
          ) {
            this.x.error =
              "Please provide passport back image before moving ahead";
            this.x.loading = false;
            return false;
          }
          if (
            !this.CNICFront &&
            this.UserType == 1 &&
            this.countrySortName == "PAK"
          ) {
            this.x.error =
              "Please provide CNIC front image before moving ahead";
            this.x.loading = false;
            return false;
          }
          if (
            !this.CNICBack &&
            this.UserType == 1 &&
            this.countrySortName == "PAK"
          ) {
            this.x.error = "Please provide CNIC back image before moving ahead";
            this.x.loading = false;
            return false;
          }
          if (this.model.length) {
            let count_valid = 0;
            // let regex = '/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/';
            var re =
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            for (let k = 0; k < this.model.length; k++) {
              this.model[k] = this.model[k].trim();

              if (re.test(this.model[k])) {
                count_valid = count_valid + 1;
              }
            }
            if (count_valid !== this.model.length) {
              this.x.error =
                "Please make sure all the emails are properly formed";

              this.loading = false;
              return false;
            }
          }

          this.loading = true;
          let url = transporter.editTransporter;
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };
          let body = {
            transporterId: this.transporterId.toString(),
            userName: this.username.trim(),
            companyName: this.companyName.trim(),
            comment: this.comment,
            emailId: this.emailId,
            jobType: this.UserType,
            countryCode: this.countryCode.dialing_code || this.countryCode,
            phoneNo: this.phoneNo,
            workingCountry: this.countrySortName,
            secondaryEmail: this.model,
            transporterType: this.type,
          };
          if (this.countrySortName == "PAK") {
            if (this.CNICFront) {
              body.cnicFrontImage = this.CNICFront;
            }
            if (this.CNICBack) {
              body.cnicBackImage = this.CNICBack;
            }
            if (this.STRNNumber) {
              body.STRNNumber = this.STRNNumber;
            }
            body.NTNNumber = this.NTNNumber;
            body.ContactPersonName = this.ContactPersonName;
          }

          if (this.UserType == 1) {
            body.tradeLicense = this.tradeLicense;
            if (this.countrySortName != "PAK") {
              body.tradeLicenseNumber = this.tradeLicenseNumber;
              body.passportNumber = this.passport;
              if (this.vatNumber.trim()) {
                body.vatNumber = this.vatNumber;
              }
            }
            if (this.vatCertificate) {
              body.vatCertificate = this.vatCertificate;
            }
            if (this.bankName) {
              body.bankName = this.bankName;
            }
            if (this.bankHolderName) {
              if (this.bankHolderName.trim()) {
                body.bankHolderName = this.bankHolderName.trim();
              }
            }
            if (this.passportFront) {
              body.passportFront = this.passportFront;
            }
            if (this.passportBack) {
              body.passportBack = this.passportBack;
            }
          }
          if (this.UserType == 2 || this.UserType == 3) {
            if (this.tradeLicense) {
              body.tradeLicense = this.tradeLicense;
            }
            if (this.countrySortName != "PAK") {
              if (this.tradeLicenseNumber) {
                body.tradeLicenseNumber = this.tradeLicenseNumber;
              }
              if (this.vatNumber.trim()) {
                body.vatNumber = this.vatNumber;
              }
              if (this.passportNumber) {
                body.passportNumber = this.passport;
              }
            }
            if (this.vatCertificate) {
              body.vatCertificate = this.vatCertificate;
            }
            if (this.passportFront) {
              body.passportFront = this.passportFront;
            }
            if (this.passportBack) {
              body.passportBack = this.passportBack;
            }
          }

          if (this.password) {
            body.password = this.password;
          }
          if (this.profilePicURL) {
            body.profilePicURL = this.profilePicURL;
          }

          this.axios.post(this.constants.apiUrl + url, body, config).then(
            (response) => {
              if (response.status === 200) {
                this.items = response.data.data;
                this.x.registerSuccess = true;

                this.loading = false;
              } else {
                this.x.error = "Failed to add transporter";
              }
            },
            (error) => {
              this.loading = false;
              this.processing = false;
              this.x.error = error.response.data.message;
            }
          );
        }
      } else {
        this.x.error = "Please fill all required fields in above form! ";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.menu,
.menu__content {
  background: white !important;
}

.thumb-img {
  border: 2px solid darkorange;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-left: 5px !important;
}
.menuable__content__active {
  min-width: 290px !important;
}
</style>
