<template>
  <v-container fluid>
    <v-card raised>
      <loading :active.sync="loading" />
      <v-tabs
        fixed-tabs
        class="m-b-20"
        background-color="darkorange"
        dark
        v-model="changeTabListing"
      >
        <v-tab :href="`#basicDetail`" @click="filterByTab('basicDetail')">
          Basic Details
        </v-tab>
        <v-tab
          v-if="transporterType == '1' && $route.params.isVerified == 'true'"
          :href="`#drivers`"
          @click="
            filterByTab('drivers');
            $router.push(
              `/transporter/driver/${$route.params.id}/${$route.params._id}/${$route.params.country}`
            );
          "
        >
          Drivers
        </v-tab>
      </v-tabs>

      <v-flex>
        <EditTransporter
          v-if="changeTabListing == 'basicDetail'"
          v-on:transporter-type="type"
        />
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import { EventBus } from "../../event-bus.js";
import EditTransporter from "./EditTransporter";
import moment from "moment";
import Loading from "vue-loading-overlay";
import { StorageKeys } from "../../constants/constants";
export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      let workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.invoicingCountry = key.value;
        }
      });
    }
    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "transporter-detail") {
        this.$router.push(`/transporter`);
      }
    });

    this.$eventBus.$on("on-load", () => {
      this.loading = true;
    });
    this.$eventBus.$on("transporter-type", (event) => {
      this.transporterType = event;
    });

    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });
  },

  mounted() {},
  components: {
    Loading,
    EditTransporter,
  },

  data: () => ({
    search: "",
    country: "",
    transporterType: "",
    active: "0",
    dialog: false,
    reset: false,
    loading: false,
    changeTabListing: "basicDetail",
  }),
  methods: {
    type(event) {},
    goback() {
      this.$router.go(-1);
    },
    filterByTab(value) {
      this.changeTabListing = value;
    },
    startLoad() {
      this.loading = true;
    },
    stopLoad() {
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.m-b-20 {
  margin-bottom: 20px !important;
}
</style>
